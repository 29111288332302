<script setup>
import { computed } from 'vue';
import LeadTable from '@/components/LeadTable.vue';
import LeadById from '@/components/LeadById.vue';
import LeadBySocialMedia from '@/components/LeadBySocialMedia.vue';
// import FacebookLogin from '@/components/FacebookLogin.vue';
const leadViewer = computed(() => {
  console.log("LEAD_VIEWER", process.env.VUE_APP_LEAD_VIEWER)
  if (process.env.VUE_APP_LEAD_VIEWER == 'true' ){
    return true;
  }
  return false;
});
</script>
<template>
  <div class="sdds-container-fluid" id="main">
    <div class="sdds-row row-tittle">
      <h3>Lead Viewer</h3>
    </div>
    <sdds-inline-tabs class="inline-tabs">
      <div v-if="leadViewer" name="Search by Market" class="tab">
        <LeadTable></LeadTable>
      </div>
      <div v-if="leadViewer" name="Search by Lead Id" class="tab">
        <LeadById></LeadById>
      </div>
      <div name="Facebook" class="tab">
        <LeadBySocialMedia></LeadBySocialMedia>
      </div>
    </sdds-inline-tabs>
  </div>
</template>
<style scoped>
.row-tittle {
  justify-content: center;
}

h3 {
  margin-top: 15px;
  margin-bottom: 2px;
}

.tab {
  min-height: 500px;
  height: auto;
}
.inline-tabs{
  height: auto;
}
</style>