import Keycloak from 'keycloak-js';

const keycloakUrl = process.env.VUE_APP_LS_KEYCLOAK_URL;
const clientId = process.env.VUE_APP_LS_KEYCLOAK_MAIN_FRONTEND_CLIENT;

const kcOptions = {
  url: `${keycloakUrl}/auth`,
  realm: 'scania',
  clientId: clientId,
};

const _keycloak = new Keycloak(kcOptions);

export default {
  // eslint-disable-next-line
  install: (app, options) => {
    app.$keycloak = _keycloak;
    Object.defineProperties(app.config.globalProperties, {
      $keycloak: {
        get() {
          return _keycloak;
        },
      },
    });
    app.provide('keycloak', app.$keycloak);
  },
};
