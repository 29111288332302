// src/utils/facebook.js
const initFacebookSDK = () => {
  return new Promise((resolve) => {
    // Load the Facebook SDK asynchronously
    window.fbAsyncInit = function () {
      FB.init({
        appId: '327510303473773',
        cookie: true,
        xfbml: true,
        version: 'v18.0', // Use the desired version of the Graph API
      });

      // Additional initialization code, if needed

      resolve();
    };

    // Load the Facebook SDK script
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  });
};

export { initFacebookSDK };
