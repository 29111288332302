<script setup>
import { inject, computed, ref } from 'vue'
import { useRoute } from 'vue-router'
/* import scaniaLogo from '@/assets/scania.svg'; */
import { useMainStore } from '@/store';

// eslint-disable-next-line
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  menuItems: {
    type: Array
  }
});

const route = useRoute();
const path = computed(() => route.name);
const userMenuOpen = ref(false);
const keycloak = inject('keycloak');
const nameUser = useMainStore()?.name;

const toggleAvatarMenu = () => {
  userMenuOpen.value = !userMenuOpen.value;
};

// eslint-disable-next-line 
const logout = () => {
  console.log('logout');
  const logoutOptions = { redirectUri: window.location.origin };
  keycloak.logout(logoutOptions).then((success) => {
    console.log('--> log: logout success ', success);
  }).catch((error) => {
    console.log('--> log: logout error ', error);
  }).finally(() => {
    return false;
  });
};

</script>
  
<template>
  <nav class='sdds-nav' :class="{ 'sdds-nav__avatar--opened': userMenuOpen }">
    <div class='sdds-nav__left'>
      <div class='sdds-nav__app-name'>{{ title }}</div>
    </div>

    <div class='sdds-nav__center'>
      <ul class='sdds-nav__inline-menu'>
        <li v-for="item in menuItems" :key="item.name" class="sdds-nav__item"
          :class="{ 'sdds-nav__item--active': path === item.name }">
          <router-link class="sdds-nav__item-core" :to="{ name: item.name }">
            <span class="sdds-nav__item-core-text">{{ item.text }}</span>
          </router-link>
        </li>
      </ul>
      <ul class='sdds-nav__toolbar-menu'>

        <li class='sdds-nav__item sdds-nav__avatar'>
          <button class='sdds-nav__avatar-btn' @click='toggleAvatarMenu()'>
            <img class="sdds-nav__avatar-img" src='https://www.svgrepo.com/show/170303/avatar.svg'
              alt='profile photo' />
            <div class='sdds-nav__avatar-info sdds-nav__avatar-info--mobile'>
              <p class='sdds-nav__avatar-title'>{{ nameUser }}</p>
            </div>
          </button>

          <ul class='sdds-nav__avatar-menu'>
            <li class='sdds-nav__avatar-item sdds-nav__avatar-item--large'>
              <div class='sdds-nav__avatar-info'>
                <p class='sdds-nav__avatar-title'>{{ nameUser }}</p>
              </div>
            </li>
            <li class='sdds-nav__avatar-item'>
              <a class='sdds-nav__avatar-item-core' @click="logout">Logout</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <div class='sdds-nav__right'>
      <a class='sdds-nav__item sdds-nav__app-logo'></a>
    </div>
  </nav>
</template>
  

<style scoped>
.sdds-nav--white {
  background-color: var(--sdds-white);
  border-bottom: 1px solid;
  border-color: var(--sdds-grey-200);
}

.sdds-nav__item {
  border-left: 1px solid;
}

.sdds-nav--white .sdds-nav__item {
  border-color: var(--sdds-grey-200);
}

.sdds-nav--white .sdds-nav__app-name {
  color: var(--sdds-blue);
}

.sdds-nav--white .sdds-nav__app-logo {
  background-color: var(--sdds-white);
}

.sdds-nav--white .sdds-nav__avatar-btn {
  background-color: var(--sdds-white);
}

.sdds-nav--white .sdds-nav__dropdown .sdds-nav__item-core {
  background-color: var(--sdds-white);
  color: black;
}

.sdds-nav__app-wordmark {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 24px;
  white-space: nowrap;
}

.sdds-nav__app-wordmark img {
  height: 1.7em;
}

.sdds-nav__app-logo {
  border-right: 0;
}

.sdds-banner {
  display: flex;
  align-items: center;
}

.sdds-banner-green {
  background-color: var(--sdds-green-100);
}

.sdds-banner-green .sdds-banner-prefix {
  color: var(--sdds-positive);
}
</style>
  