<script setup>
import { computed, onMounted } from 'vue';

// eslint-disable-next-line
const emit = defineEmits(['update:selection']);

// eslint-disable-next-line
const props = defineProps({
  idDrop: {
    type: String,
    default: null,
    required: true,
  },
  selection: {
    type: String,
    default: null,
  },
  options: {
    type: Array,
    default: () => [],
  },
  size: {
    type: String,
    default: 'Large',
  },
  labelDescription: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
});

const selectedValue = computed(() => (props.selection));

const isThereALabel = computed(() => (props.labelDescription.length > 0));

const isShowMessageRequired = computed(() => (!!(props.required && (selectedValue.value ?? '*') === '*')));

const getMessageHelper = computed(() => (props.required && (selectedValue.value ?? '*') === '*' ? 'Field Required' : null));

function handleSelectOption(event) {
  // console.log('handleSelectOption: ', event);
  emit('update:selection', { keyDropdown: props.idDrop, value: event.detail.value });
}

onMounted(() => {
  if (props.idDrop) {
    // console.log('props.idDrop: ', props.idDrop);
    const dropdownElement = document.getElementById(props.idDrop);
    dropdownElement?.addEventListener('selectOption', handleSelectOption);
    var data = [];
    props.options.forEach(item => {
      data.push({ value: item.key, label: item.value });
    });
    dropdownElement.data = JSON.stringify(data);
  }
});
</script>

<template>
  <label for="props.idDrop" v-if="isThereALabel">{{ labelDescription }}
    <input id="props.idDrop" v-if="false" /> </label>
  <div style="width: 100%">
    <sdds-dropdown-filter :id="props.idDrop" placeholder="Select option" label="Label text" :size="size"
      :default-option="selectedValue" :selected-option="selectedValue" :state="isShowMessageRequired ? 'error' : ''"
      :helper="getMessageHelper">
    </sdds-dropdown-filter>
  </div>
</template>

<style scoped>
sdds-dropdown {
  position: inherit;
}
</style>
