<script setup>
import { computed } from 'vue'
import download from '@/assets/download.svg';
// eslint-disable-next-line
const props = defineProps({
  lead: {
    type: Object,
    required: true,
  },
});


const fullLeadData = computed(() => {
  const leadInfo = Object.entries(props.lead).filter(item => item[1] && item[0] !== 'LeadPDFFile' && item[0] !== 'Specification');
  console.log('leadInfo', leadInfo.length);
  const middleIndex = Math.ceil(leadInfo.length / 2);
  return [leadInfo.splice(0, middleIndex), leadInfo.splice(-middleIndex)];
});

const showPdf = computed(() => {
  return props.lead.LeadPDFFile?.length > 0 ?? false;
});

const showSpecification = computed(() => {
  return props.lead.Specification ? true : false;
});

const dataPdf = computed(() => {
  return 'data:application/pdf;base64,' + props.lead.LeadPDFFile[0]?.File
});

const dataXml = computed(() => {
  return 'data:text/plain;base64,' + props.lead.Specification
});

</script>
<template>
  <div>
    <div v-if="fullLeadData" class="float-left-lead">
      <ul>
        <li v-if="showPdf">
          <a class="sdds-link" :href="dataPdf" download=Specification.pdf>Specification.pdf<span>
              <img :src="download" class="img-link" /> </span></a>
        </li>
        <li v-if="showSpecification">
          <a class="sdds-link" :href="dataXml" download=Specification.xml>Specification.xml<span>
              <img :src="download" class="img-link"  /> </span></a>
        </li>
        <li v-for="subKey in fullLeadData[0]" :key="subKey">
          <strong>{{ subKey[0] }}: </strong>
          <span>
            {{ subKey[1]}}
          </span>
        </li>
      </ul>
    </div>
    <div v-if="fullLeadData" class="float-right-lead">
      <ul>
        <li v-for="subKey in fullLeadData[1]" :key="subKey">
          <strong>{{ subKey[0] }}: </strong>
          <span>
            {{ subKey[1]}}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<style scoped>
.float-left-lead {
  width: 50%;
  float: left;
  text-align: left;
}

.float-right-lead {
  width: 50%;
  float: right;
  text-align: left;
}
.img-link{
  height: 15px;
}
</style>