<script setup>
import { ref, watch, computed } from 'vue';
import Datepicker from 'vue3-datepicker'
import SddsDropdownFilter from '@/components/common/BaseSddsDropdownFilter.vue';
import LeadDetails from '@/components/LeadDetails.vue';
import che_down from '@/assets/chevron_down.svg';
import che_up from '@/assets/chevron_up.svg';
import { getOptionsMarkets } from '@/helpers/optionsTemplate';
import { getLeadByMarket, getLeadById } from '@/services/leadService';
import { useMainStore } from '@/store';

const store = useMainStore();

const componentKey = ref(0);
const date = new Date();
const leadsData = ref([]);
const pickedStart = ref(new Date(date.getFullYear(), date.getMonth(), 1));
const pickedEnd = ref(new Date());
const loadingLeads = ref(false);
const loadingLeadData = ref(false);
const marketSelection = ref(null);
const generatorSelection = ref(null);

const currentPage = ref(1);
const elementsPerPage = 10;

const generators = computed(() => {
  const values =
    store.systemConfig?.filter(i => i.allowedOperations.includes('AddLead'))
      .map(i => ({ 'key': i.systemName, 'value': i.systemName + ' <-> ' + i.applicationName })) ?? [];

  values.unshift({ 'key': '*', 'value': 'All' });
  return values;
});

const getLeadsbyMarket = async () => {
  const startDate = new Date(pickedStart.value.toDateString());
  startDate.setHours(0, 0, 0, 0);
  console.log(startDate);

  const endDate = new Date(pickedEnd.value.toDateString());
  endDate.setHours(23, 59, 59, 999);
  console.log(endDate);

  const from = startDate.toISOString().replace('.000Z', 'Z');
  const to = endDate.toISOString().replace('.999Z', 'Z');

  console.log('marketSelection.value: ', marketSelection.value);
  if (!marketSelection.value) {
    return;
  }

  const request = {
    from,
    to,
    source: (generatorSelection.value === '*') ? null : generatorSelection.value
  };
  console.log('request: ', request);
  leadsData.value.length = 0;
  loadingLeads.value = true;
  getLeadByMarket(marketSelection.value, request)
    .then((response) => {
      console.log('response-getLeadByMarket: ', response);
      const data = JSON.parse(response.data);
      leadsData.value.push(...data);
      componentKey.value += 1;
      currentPage.value =1;
    }).catch((error) => {
      console.log('error-getLeadByMarket: ', error.toJSON());
      componentKey.value += 1;
    }).finally(() => {
      loadingLeads.value = false;
    });
};

const handleUpdateSelectionMarket = (selection) => {
  console.log('handleUpdateSelectionMarket: ', selection);
  marketSelection.value = selection.value;
};

const handleUpdateSelectionGenerator = (selection) => {
  console.log('handleUpdateSelectionGenerator: ', selection);
  generatorSelection.value = selection.value;
};

const get_rows = () => {
  var start = (currentPage.value - 1) * elementsPerPage;
  var end = start + elementsPerPage;
  return leadsData.value.slice(start, end);
}
const num_pages = () => {
  return Math.ceil(leadsData.value.length / elementsPerPage);
}

const toISOStringWithTimezone = date => {
  const tzOffset = -date.getTimezoneOffset();
  const diff = tzOffset >= 0 ? '+' : '-';
  const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
  return date.getFullYear() +
    '-' + pad(date.getMonth() + 1) +
    '-' + pad(date.getDate()) +
    ' ' + pad(date.getHours()) +
    ':' + pad(date.getMinutes()) +
    ':' + pad(date.getSeconds()) +
    diff + pad(tzOffset / 60) +
    ':' + pad(tzOffset % 60);
};

const getLocalDate = (date) => toISOStringWithTimezone(new Date(date));

const getLeadData = async (item) => {
  loadingLeadData.value = true;
  getLeadById(item.LeadId)
    .then((response) => {
      console.log('response-getLeadById: ', response);
      item.fullLeadData = JSON.parse(response.data);
    }).catch((error) => {
      console.log('error-getLeadById: ', error.toJSON());
    }).finally(() => {
      loadingLeadData.value = false;
    });
};

const viewLeadData = async (item) => {
  item.visible = !item.visible;
  if (item.visible && !item.fullLeadData) {
    getLeadData(item);
  }
};

watch([marketSelection, pickedStart, pickedEnd, generatorSelection], getLeadsbyMarket);
</script>

<template>
  <div class="sdds-row main-content">
    <div class="filter-in-row">
      <div class="sdds-col-md-4">
        <SddsDropdownFilter :selection="marketSelection" id-drop="market" size="small" label-description="Market"
          :options="getOptionsMarkets()" :required="true" @update:selection="handleUpdateSelectionMarket" />
      </div>
      <div class="sdds-col-md-4" v-if="generators.length > 1">
        <SddsDropdownFilter :selection="generatorSelection" id-drop="generator" size="small"
          label-description="Lead Generator" :options="generators" @update:selection="handleUpdateSelectionGenerator" />
      </div>
      <div class="div-date-picker">
        <div class="sdds-datetime-input-container input-picker">
          <label>Start date</label>
          <div>
            <datepicker class="sdds-textfield-input sdds-textfield-input-date sdds-date-picker" v-model="pickedStart"
              :upperLimit="pickedEnd" />
            <div class="datetime-icon icon-datetime-local icon-date">
              <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M23.625 2.977a1 1 0 1 0-2 0v2.985l-11.344.01V2.99a1 1 0 1 0-2 0v2.985H6.25a4.2 4.2 0 0 0-4.2 4.2V25.77a4.2 4.2 0 0 0 4.2 4.2h19.54a4.2 4.2 0 0 0 4.2-4.2V10.167a4.2 4.2 0 0 0-4.191-4.2l-2.174-.004V2.977ZM4.05 10.174a2.2 2.2 0 0 1 2.2-2.2l16.376-.013 3.17.006a2.2 2.2 0 0 1 2.195 2.2v1.847H4.05l-.001-1.84Zm0 3.84V25.77a2.2 2.2 0 0 0 2.2 2.2h19.54a2.2 2.2 0 0 0 2.2-2.2V14.014H4.05Z"
                  fill="currentColor"></path>
              </svg>
            </div>
          </div>
        </div>
        <div class="sdds-datetime-input-container input-picker">
          <label>End date</label>
          <datepicker class="sdds-textfield-input sdds-textfield-input-date sdds-date-picker" v-model="pickedEnd"
            :lowerLimit="pickedStart" />
          <div class="datetime-icon icon-datetime-local icon-date">
            <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M23.625 2.977a1 1 0 1 0-2 0v2.985l-11.344.01V2.99a1 1 0 1 0-2 0v2.985H6.25a4.2 4.2 0 0 0-4.2 4.2V25.77a4.2 4.2 0 0 0 4.2 4.2h19.54a4.2 4.2 0 0 0 4.2-4.2V10.167a4.2 4.2 0 0 0-4.191-4.2l-2.174-.004V2.977ZM4.05 10.174a2.2 2.2 0 0 1 2.2-2.2l16.376-.013 3.17.006a2.2 2.2 0 0 1 2.195 2.2v1.847H4.05l-.001-1.84Zm0 3.84V25.77a2.2 2.2 0 0 0 2.2 2.2h19.54a2.2 2.2 0 0 0 2.2-2.2V14.014H4.05Z"
                fill="currentColor"></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <table class="sdds-table sdds-table--compact table">
      <thead class="sdds-table__header">
        <tr class="sdds-table__row">
          <th id="col-action" class="sdds-table__header-cell sdds-compact"></th>
          <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">LeadId</th>
          <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Type</th>
          <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Source</th>
          <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Created On</th>
          <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Opportunity Key</th>
        </tr>
      </thead>
      <tbody class="sdds-table__body">
        <template v-for="(item, index)  in get_rows()" :key="index">
          <tr class="sdds-table__row" :class="{ 'sdds-row-selected': item.showDetails }">
            <td class="">
              <button class="sdds-btn sdds-btn-sm sdds-btn-ghost" @click="viewLeadData(item)">
                <span>
                  <img :src="che_down" v-if="!item.visible" />
                  <img :src="che_up" v-if="item.visible" />
                </span>
              </button>
            </td>
            <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 row-value">
              {{ item.LeadId }}
            </td>
            <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 row-value">
              {{ item.Type }}
            </td>
            <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 row-value">
              {{ item.Source }}
            </td>
            <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 row-value">
              {{ getLocalDate(item.CreatedOn) }}
            </td>
            <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 row-value">
              {{ item.OpportunityKey }}
            </td>
          </tr>
          <tr class="sdds-table__row" v-if="item.visible">
            <td colspan="6" v-if="loadingLeadData">
              <div class="spinner fa-4x center-in-row">
                <font-awesome-icon icon="spinner" class="fa-spin" />
              </div>
            </td>
            <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2" colspan="6" v-if="!loadingLeadData">
              <lead-details :lead="item.fullLeadData"></lead-details>
            </td>
          </tr>
        </template>
      </tbody>
      <tfoot class="sdds-table__footer" v-if="num_pages() > 1">
        <tr class="sdds-table__footer-row">
          <td class="sdds-table__footer-cell" colspan="6">
            <div class="sdds-table__pagination">
              <div class="sdds-table__row-selector"></div>
              <div class="sdds-table__page-selector">
                <input class="sdds-table__page-selector-input sdds-input-numberpage" type="number" min="1" id="num-page"
                  :max=num_pages() pattern="[0-9]+" dir="rtl" v-model="currentPage" />
                <p class="sdds-table__footer-text">
                  of
                  <span>
                    {{ num_pages() }}
                  </span>
                  pages
                </p>
                <button class="sdds-table__footer-btn" :disabled="currentPage <= 1" @click="currentPage--">
                  <svg class="sdds-table__footer-btn-svg" fill="none" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M22.217 4.273a1 1 0 0 1 0 1.415l-9.888 9.888a.6.6 0 0 0 0 .848l9.888 9.888a1 1 0 0 1-1.414 1.415l-9.889-9.889a2.6 2.6 0 0 1 0-3.677l9.889-9.888a1 1 0 0 1 1.414 0Z"
                      fill="currentColor" />
                  </svg>
                </button>
                <button class="sdds-table__footer-btn" :disabled="currentPage >= num_pages()" @click="currentPage++">
                  <svg class="sdds-table__footer-btn-svg" fill="none" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M9.783 27.727a1 1 0 0 1 0-1.415l9.888-9.888a.6.6 0 0 0 0-.848L9.783 5.688a1 1 0 0 1 1.414-1.415l9.889 9.889a2.6 2.6 0 0 1 0 3.676l-9.889 9.889a1 1 0 0 1-1.414 0Z"
                      fill="currentColor" />
                  </svg>
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
    <div class="spinner fa-4x center-in-row" v-if="loadingLeads">
      <font-awesome-icon icon="spinner" class="fa-spin" />
    </div>
  </div>
</template>

<style scoped>

.main-content{
  margin-top: -15px;
}
#col-action.sdds-compact {
  min-width: auto;
  text-align: center;
}

.center-in-row {
  margin: auto;
}

.sdds-btn-ghost:hover {
  border: none;
}

.sdds-row-selected {
  background-color: #E4E9F1;
}

.row-tit-dat {
  justify-content: space-between;
  margin-top: 25px;
}

.row-tit-details {
  margin-top: 27px;
}

.icon-date {
  top: 60%
}

.filter-in-row {
  display: flex;
  justify-content: left;
  text-align: left;
  margin-left: -15px;
  align-items: baseline;
}

.center-in-row {
  margin: auto;
}

.table {
  width: 100%;
}

.div-date-picker {
  margin-bottom: 10px;
  display: flex;
}

.div-date-picker>div {
  margin-left: 20px;
}

.div-date-picker :deep(.sdds-date-picker) {
  padding-top: 15px;
  padding-bottom: 15px;
}

.row-value {
  text-align: left;
}


.input-picker :deep(.sdds-date-picker) {
  min-width: 150px;
  height: 41px;
}
</style>