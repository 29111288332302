<script setup>
import { ref } from 'vue';
import LeadDetails from '@/components/LeadDetails.vue';
import { getLeadById } from '@/services/leadService';

const loadingLeadData = ref(false);
const fullLeadData = ref({});
const leadId = ref(null);

const getLeadData = async () => {
  loadingLeadData.value = true;
  fullLeadData.value = {}
  getLeadById(leadId.value)
    .then((response) => {
      console.log('response-getLeadById: ', response);
      fullLeadData.value = JSON.parse(response.data);
    }).catch((error) => {
      console.log('error-getLeadById: ', error.toJSON());
    }).finally(() => {
      loadingLeadData.value = false;
    });
};
</script>
<template>
  <div class="sdds-row">
    <div class="tool-bar">
      <sdds-textfield size="md" placeholder="Search by Lead ID" class="textfield" v-model="leadId" @keyup.enter="getLeadData"></sdds-textfield>
      <button class="sdds-btn sdds-btn-secondary" @click="getLeadData">Search</button>
    </div>
  </div>
  <div class="sdds-row">
    <div v-if="loadingLeadData" class="spinner fa-4x center-in-row">
      <font-awesome-icon icon="spinner" class="fa-spin" />
    </div>
    <div v-if="!loadingLeadData">
      <lead-details :lead="fullLeadData"></lead-details>
    </div>
  </div>
</template>
<style scoped>
.tool-bar {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.textfield {
  min-width: 400px;
  padding-top: 5px;
}
.center-in-row {
  margin: auto;
}
</style>