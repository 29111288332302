// src/views/FacebookLogin.vue
<template>
  <!-- <div class="fbMarketValue">{{ marketSelection }}</div> -->
  <div v-show="fblogin">
    <button class="sdds-btn sdds-btn-primary sdds-btn-md" @click="loginWithFacebook">Login with Facebook</button>
  </div>
  <div v-show="fblogout">
    <button class="sdds-btn sdds-btn-danger sdds-btn-md" @click="logoutFacebook">Logout</button>
    
  </div>
</template>

<script>
import { initFacebookSDK } from '@/services/facebook';
import { addSmConfig } from '@/services/leadService';

export default {
  props: {
    selectedData: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fblogin: true,
      fblogout: false
    }
  },
  methods: {
    async logoutFacebook() {
      FB.logout(function(response){
        console.log(response)
        this.fblogin = true;
        this.fblogout = false;
        console.log("Logged Out!");
        alert("Logged Out!")
        location.reload();
    });
    },
    async loginWithFacebook() {
      console.log("inside fb component login click", this.selectedData)
      
      await initFacebookSDK();
      const fbmarket = this.selectedData;
      this.fblogin = false;
      this.fblogout = true;
      FB.login(function(response) {
      if (response.authResponse) {
        console.log('Welcome!  Fetching your information.... ');
        const accessToken = response.authResponse.accessToken;
        FB.api('/me/accounts', function(response) {
          console.log("response: ", response)
          const pages = response.data.length;
          const pageid = response.data[0]['id']
          const pagetitle = response.data[0]['name']
          if (pages == 1){
            const payload = JSON.stringify({'pageid': `${pageid}`,'pagename': `${pagetitle}`,'smtype': 'facebook','market': `${fbmarket}`,'fbtoken': `${accessToken}`});
            // console.log("payload", payload)
            addSmConfig(payload)
            .then((response) => {
              console.log('response-add sm config: ', response);
              alert(fbmarket + " Configured Successfully.");
              location.reload();
            }).catch((error) => {
              console.log('error-update-sm-config: ', JSON.stringify(error));
              // location.reload();
            }).finally(() => {
              location.reload();
            });
          } else {
            console.log("Please select only one page");
            alert("Please select only one page");
            FB.logout(function(response){
                console.log(response)
                this.fblogin = true;
                this.fblogout = false;
                console.log("Logged Out!");
                location.reload();
            });

            //logout and show login again
          }  
        });
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, {scope: 'leads_retrieval,pages_manage_metadata,pages_show_list'});
    },
  },
};
</script>
