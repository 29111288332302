import { createRouter as createVueRouter, createWebHashHistory } from 'vue-router'
import ViewerView from '@/views/LeadViewer.vue'
import HomeView from '@/views/HomeView.vue';

const requiredRole = process.env.VUE_APP_LS_KEYCLOAK_REQUIRED_ROLE;
const frontendClient = process.env.VUE_APP_LS_KEYCLOAK_MAIN_FRONTEND_CLIENT;

const isAuthorized = (app) => {
  return app.config.globalProperties.$keycloak.authenticated
    && app.config.globalProperties.$keycloak.hasResourceRole(requiredRole, frontendClient);
};

// eslint-disable-next-line no-unused-vars
const createRouter = (app) => {
  const router = createVueRouter({
    history: createWebHashHistory(),
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { top: 0 };
      }
    },
    routes: [
      {
        path: '/',
        name: 'Home',
        component: HomeView,
        // eslint-disable-next-line no-unused-vars
        beforeEnter: (to, from) => {
          if (isAuthorized(app)) {
            return ({ name: 'viewer' });
          }
        },
      },
      {
        path: '/viewer',
        name: 'viewer',
        component: ViewerView,
        // eslint-disable-next-line no-unused-vars
        beforeEnter: (to, from) => {
          if (!isAuthorized(app)) {
            return ({ name: 'Home' });
          }
        },
      }
    ]
  });
  return router;
};

export {
  createRouter
}
