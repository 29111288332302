import { createApp } from 'vue'
import { defineCustomElements, addTheme } from '@scania/components';
import { theme as scania } from '@scania/theme-light';
import App from '@/App.vue'
import { createPinia } from 'pinia';
import { createRouter } from '@/router'
import keycloakAuth from '@/plugins/keycloakAuth';
import { useMainStore } from '@/store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

library.add(faSpinner);

defineCustomElements();
addTheme(scania);

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);

// Add v-event directive for handling custom events from SDDS components/StencilJS
// v-event:<custom event name>="<function>" for example
// For future reference, see https://github.com/vuejs/core/issues/5401
app.directive('event', {
  beforeMount(el, { arg, value }) {
    el.addEventListener(arg, value);
  },
  beforeUnmount(el, { arg, value }) {
    el.removeEventListener(arg, value);
  },
});

app.use(createPinia());
app.use(keycloakAuth);

const kcInitOptions = {
  onLoad: 'login-required',
  pkceMethod: 'S256',
  checkLoginIframe: false,
};

const loadData = (keycloak) => {
  console.log('loadData auth');
  const store = useMainStore();
  store.token = keycloak.token;
  // console.log('token: ', store.token);
  if (keycloak.idToken) {
    console.log('IDToken');
    // console.log(keycloak.idTokenParsed);
    store.name = keycloak.idTokenParsed?.name;
    store.userId = keycloak.idTokenParsed?.preferred_username;
  }
};

app.$keycloak
  .init(kcInitOptions)
  .then((auth) => {
    if (!auth) {
      console.log('Not authenticated');
    } else {
      console.log('Authenticated');
      loadData(app.$keycloak);
    }
  }).catch(function (error) {
    console.log('failed to initialize', error);
  }).finally(() => {
    app.use(createRouter(app));
    app.mount('#app');
  });