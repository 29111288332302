<script setup>
import Header from './components/TheSddsHeader.vue';
import { inject, onMounted } from 'vue';
import { useMainStore } from '@/store';
import { getSystemConfig } from './services/leadService';

const requiredRole = process.env.VUE_APP_LS_KEYCLOAK_REQUIRED_ROLE;
const frontendClient = process.env.VUE_APP_LS_KEYCLOAK_MAIN_FRONTEND_CLIENT;

const keycloak = inject('keycloak');
const store = useMainStore();

const updateToken = () => {
  keycloak.updateToken(90).then(() => {
    if (!keycloak.hasResourceRole(requiredRole, frontendClient)) {
      const logoutOptions = { redirectUri: window.location.origin };
      keycloak.logout(logoutOptions).then((success) => {
        console.log('--> log: logout success ', success);
      }).catch((error) => {
        console.log('--> log: logout error ', error);
      }).finally(() => {
        return false;
      });
    }
    console.log('updateToken');
    store.token = keycloak.token;
    return true;
  }).catch(() => {
    // handle this in some way
    return false;
  });
};

// Token lives for 5 minutes, refresh token lives for 1 hour, session lasts for 10 hours.
// Update token every 4 minutes to avoid any potential issues
// eslint-disable-next-line no-unused-vars
const updateTokenIntervalHandle = setInterval(updateToken, 240000);

const menuItems = [
  { name: 'viewer', text: 'Lead Viewer' },
];

onMounted(() => {
  loadApplicationData();
});

const loadApplicationData = async () => {
  const response = await getSystemConfig();
  store.systemConfig = response.data;
};
</script>

<template>
  <sdds-theme />
  <Header title="Lead Admin Tool" :menu-items="menuItems" />
  <div>
    <router-view />
  </div>
</template>

<style>
html {
  height: 100%;
  background-color: var(--sdds-grey-50);
}

.superpower {
  min-height: calc(100vh - 64px);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
