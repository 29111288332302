import axios from 'axios';
import { useMainStore } from '@/store';


const api = axios.create({
  baseURL: process.env.VUE_APP_LS_API_URL,
});

api.interceptors.request.use((config) => {
  const store = useMainStore();
  config.headers.Authorization = store.token;
  return config;
}, (error) => {
  return Promise.reject(error);
});

const getLeadByMarket = async (marketId, fileRequest) => api.get(`/v1/markets/${marketId}/leads`, { params: fileRequest });
const getLeadById = async (leadId) => api.get(`v1/leads/${leadId}`);
const getSystemConfig = async () => api.get(`v1/config`);
const addSmConfig = async (payload) => api.post(`v1/config/addsm`, payload);
const getSmConfig = async () => api.get(`v1/config/getsm`);

export {
  getLeadByMarket,
  getLeadById,
  getSystemConfig,
  addSmConfig,
  getSmConfig,
};