<script setup>
import SddsDropdownFilter from '@/components/common/BaseSddsDropdownFilter.vue';
import { getOptionsMarkets } from '@/helpers/optionsTemplate';
import { getSmConfig } from '@/services/leadService'
import FacebookLogin from '@/components/FacebookLogin.vue'

</script>
<template>
  <div>
    <div class="sdds-row">
      <div style="width:208px"> 
        <SddsDropdownFilter :selection="marketSelection" id-drop="sm-market-list" size="small" :options="getOptionsMarkets()" :required="true" @update:selection="handleUpdateSelectionMarket" />
      </div>
      <div style="width:208px">
        <FacebookLogin :selectedData="selectedData" ></FacebookLogin>
      </div>
    </div>
    <table class="sdds-table sdds-table--compact table">
      <thead class="sdds-table__header">
        <tr class="sdds-table__row">
          <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Market</th>
          <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Page ID</th>
          <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Page Name</th>
          <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Status</th>
        </tr>
      </thead>

      <tbody class="sdds-table__body"> 
        <!-- <pre>{{ JSON.stringify(fullSmData, null, 2) }}</pre> -->
          <tr class="sdds-table__row" v-for="smconfig in fullSmData" :key="smconfig.smid">
            <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 row-value">
              <div style="width:208px; text-align: left;">{{ smconfig.market}}</div>
            </td>
            <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 row-value">
              <div style="width:208px; text-align: left;">{{ smconfig.pageid}}</div>
            </td>
            <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 row-value">
              <div style="width:208px; text-align: left;">{{ smconfig.pagename}}</div>
            </td>
            <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 row-value">
              <template v-if="smconfig.pagetoken != null">
                <div style="width:208px; text-align: left;">
                  Connected
                </div>
              </template>
              <template v-else>
                <div style="width:208px; text-align: left;">
                  Not Connected
                </div>
              </template>
              
            </td>
          </tr>
      </tbody>
    </table>
  </div>
  <div>
  </div>
</template>
<style scoped>
.tool-bar {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.textfield {
  min-width: 400px;
  padding-top: 5px;
}
.center-in-row {
  margin: auto;
}
</style>
<script>
export default {
  components: {
    FacebookLogin
  },
  data() {
    return {
      fullSmData: [],
      loadingSmData: true,
      selectedData: null,
      
    };
  },
  methods: {
    handleUpdateSelectionMarket(selection){
      console.log('handleUpdateSelectionMarket: ', selection);
      this.selectedData = selection.value;
      console.log('handle change marketSelection: ', this.selectedData);
    },
    getSMData() {
      getSmConfig()
        .then((response) => {
          console.log('response-getSmConfig: ', response.data);
          this.fullSmData = response.data;
          console.log("full:",this.fullSmData)
        }).catch((error) => {
          console.log('error-getSmConfig: ', JSON.stringify(error));
        }).finally(() => {
          this.loadingSmData = false;
        });
    },
  },
  created() {
    this.getSMData();
  },
};
</script>